export const LABELED_VEHICLES_LOADING = "LABELED_VEHICLES_LOADING";
export const LABELED_VEHICLES_SUCCESS = "LABELED_VEHICLES_SUCCESS";

export type LabeledVehicles = {
  label: string;
  value: string;
};

export interface LabeledVehiclesLoading {
  type: typeof LABELED_VEHICLES_LOADING;
}

export interface LabeledVehiclesSuccess {
  type: typeof LABELED_VEHICLES_SUCCESS;
  payload: Array<LabeledVehicles>;
}

export type LabeledVehiclesDispatchTypes =
  | LabeledVehiclesLoading
  | LabeledVehiclesSuccess;
